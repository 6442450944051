<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="activity_content">
            <el-card shadow="always">
              <div class="activity_top_content">
                <span class="countActivity"
                  >{{ pageData.activityList.length
                  }}{{ $t("activity.span_countactivity") }}</span
                >
                <el-alert
                  style="margin-bottom: 15px"
                  type="info"
                  :description="
                    $t('activity.alert_notice_errorquestionnaire_body')
                  "
                  :closable="false"
                  show-icon
                  effect="dark"
                ></el-alert>
                <el-button
                  size="small"
                  type="primary"
                  @click="openActivityAdder"
                  >{{ $t("activity.btn_addactivity") }}</el-button
                >
                <el-dialog
                  width="20%"
                  :title="$t('activity.title_add_activity')"
                  v-model="pageData.activityAdderVisible"
                  ref="activityAdder"
                  :modal="true"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :center="true"
                >
                  <el-form
                    :model="formData.newActivity"
                    ref="newActivityForm"
                    class="newActivityForm"
                    size="mini"
                  >
                    <el-form-item :label="$t('activity.label_activity')">
                      <el-input
                        v-model="formData.newActivity.name"
                        maxlength="70"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('activity.label_activity_en')">
                      <el-input
                        v-model="formData.newActivity.name_en"
                        maxlength="70"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span>
                      <el-button
                        class="addActivityBtn"
                        type="success"
                        size="medium"
                        @click="doAddActivity(formData.newActivity)"
                        :loading="pageData.doAddActivityLoading"
                        >{{ $t("activity.btn_save") }}</el-button
                      >
                    </span>
                  </template>
                </el-dialog>
              </div>
              <div class="activity_body_content">
                <el-table
                  ref="activityTable"
                  :header-cell-style="{
                    textAlign: 'left',
                    backgroundColor: '#f2f6fc',
                  }"
                  :cell-style="{ textAlign: 'left' }"
                  :data="pageData.activityList"
                >
                  <el-table-column :label="$t('activity.label_activity_title')">
                    <template #default="scope">
                      <span v-if="pageData.lang == 'ch'">{{
                        scope.row.name
                      }}</span>

                      <span v-else-if="pageData.lang == 'en'">
                        {{ scope.row.name_en }}</span
                      >
                      <span v-else> {{ scope.row.name_en }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('activity.label_options')">
                    <template #default="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="reviseActPrimary(scope.row)"
                        >{{ $t("activity.btn_primary") }}</el-button
                      >
                      <el-button
                        type="success"
                        size="mini"
                        @click="setActQuestionnaire(scope.row)"
                        v-loading.fullscreen.lock="
                          pageData.setActQuestionnaireLoading
                        "
                        >{{ $t("activity.btn_questionnaire") }}</el-button
                      >
                      <el-button
                        type="warning"
                        size="mini"
                        @click="setActParticipant(scope.row)"
                        v-loading.fullscreen.lock="
                          pageData.setActPacrticipantLoading
                        "
                        >{{ $t("activity.btn_participant") }}</el-button
                      >
                      <el-button
                        type="danger"
                        size="mini"
                        @click="setActRelations(scope.row)"
                        v-loading.fullscreen.lock="
                          pageData.setActRelationsLoading
                        "
                        >{{ $t("activity.btn_relations") }}</el-button
                      >
                      <el-button
                        type="success"
                        icon="el-icon-s-flag"
                        circle
                        size="mini"
                        v-if="
                          scope.row.qstatus === 1 && scope.row.rstatus === 1
                        "
                        :disabled="scope.row.status !== 0"
                        @click="startActivity(scope.row)"
                      ></el-button>
                      <span
                        v-if="scope.row.incompleteUserCh.length > 0"
                        style="margin-left: 5px; vertical-align: middle"
                      >
                        <el-popover
                          v-if="pageData.lang == 'ch'"
                          placement="bottom"
                          :title="$t('activity.title_incomplete_users')"
                          :width="400"
                          trigger="hover"
                          :content="scope.row.incompleteUserCh"
                        >
                          <template #reference>
                            <el-button
                              type="warning"
                              icon="el-icon-s-custom"
                              size="mini"
                              circle
                            ></el-button>
                          </template>
                        </el-popover>
                        <el-popover
                          v-else
                          placement="bottom"
                          :title="$t('activity.title_incomplete_users')"
                          :width="400"
                          trigger="hover"
                          :content="scope.row.incompleteUserEn"
                        >
                          <template #reference>
                            <el-button
                              type="warning"
                              icon="el-icon-s-custom"
                              size="mini"
                              circle
                            ></el-button>
                          </template>
                        </el-popover>
                      </span>
                      <span v-else></span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('activity.label_process')">
                    <!-- (Math.round(scope.row.process * 100) / 100) * 100 -->
                    <template #default="scope">
                      <el-progress
                        :percentage="Number(scope.row.process.toFixed(2)) * 100"
                        :format="actFormatter"
                        type="circle"
                        :width="65"
                        :color="pageData.autoColors"
                        v-if="scope.row.status !== 0"
                      ></el-progress>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="activity_dialog_content">
                  <el-dialog
                    width="20%"
                    :title="$t('activity.title_revise_activity')"
                    v-model="pageData.activityReviserVisible"
                    ref="activityReviser"
                    :modal="true"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    :center="true"
                  >
                    <el-form
                      :model="formData.editActivity"
                      ref="editActivityForm"
                      class="editActivityForm"
                      size="mini"
                    >
                      <el-form-item :label="$t('activity.label_activity')">
                        <el-input
                          v-model="formData.editActivity.name"
                          maxlength="70"
                          show-word-limit
                          size="medium"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>

                      <el-form-item :label="$t('activity.label_activity_en')">
                        <el-input
                          v-model="formData.editActivity.name_en"
                          maxlength="70"
                          show-word-limit
                          size="medium"
                          :placeholder="$t('text.placeholder')"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <template #footer>
                      <span>
                        <el-button
                          class="editActivityBtn"
                          type="success"
                          size="medium"
                          @click="doEditActPrimary(formData.editActivity)"
                          :loading="pageData.doEditActivityLoading"
                          >{{ $t("activity.btn_save") }}</el-button
                        >
                      </span>
                    </template>
                  </el-dialog>

                  <el-dialog
                    :title="$t('activity.title_set_questionnaire')"
                    v-model="pageData.setQuestionnaireEditerVisible"
                    ref="setQuestionnaireEditer"
                    :modal="true"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    :center="true"
                  >
                    <el-alert
                      :title="
                        $t('activity.alert_disabled_set_questionnaire_title')
                      "
                      type="warning"
                      :description="
                        $t('activity.alert_disabled_set_questionnaire_body')
                      "
                      :closable="false"
                      show-icon
                    >
                    </el-alert>
                    <el-form
                      :model="formData.setQuestionnaire"
                      ref="setQuestionnaireForm"
                      class="setQuestionnaireForm"
                      size="mini"
                    >
                      <el-form-item>
                        <div class="set_questionnaire_transfer_content">
                          <el-transfer
                            v-model="
                              formData.setQuestionnaire.questionnaireList
                            "
                            filterable
                            target-order="push"
                            :filter-placeholder="$t('text.placeholder')"
                            :titles="[
                              $t('activity.title_allquestionnaires'),
                              $t('activity.title_selectedquestionnaires'),
                            ]"
                            :data="pageData.enableQuestionnaireList"
                            :props="{
                              key: 'id',
                              label: 'title',
                            }"
                          >
                            <template #default="{ option }">
                              <div v-if="pageData.lang == 'ch'">
                                {{ option.title }}
                              </div>
                              <div v-else-if="pageData.lang == 'en'">
                                {{ option.title_en }}
                              </div>
                              <div v-else>
                                {{ option.title_en }}
                              </div>
                            </template>
                          </el-transfer>
                        </div>
                      </el-form-item>
                    </el-form>
                    <template #footer>
                      <span>
                        <el-button
                          class="editActivityBtn"
                          type="success"
                          size="medium"
                          :disabled="
                            pageData.doEditQuestionnaireForActBtnDisabled
                          "
                          @click="
                            doEditQuestionnaireForAct(formData.setQuestionnaire)
                          "
                          :loading="pageData.doEditQuestionnaireForActLoading"
                          >{{ $t("target.btn_save") }}</el-button
                        >
                      </span>
                    </template>
                  </el-dialog>

                  <el-dialog
                    :title="$t('activity.title_set_participant')"
                    v-model="pageData.setParticipantEditerVisible"
                    ref="setParticipantEditer"
                    :modal="true"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    :center="true"
                  >
                    <el-alert
                      :title="
                        $t('activity.alert_disabled_seteparticipat_title')
                      "
                      type="warning"
                      :description="
                        $t('activity.alert_disabled_seteparticipat_body')
                      "
                      :closable="false"
                      show-icon
                    >
                    </el-alert>
                    <el-form
                      :model="formData.setParticipant"
                      ref="setParticipantForm"
                      class="setParticipantForm"
                      size="mini"
                    >
                      <el-form-item>
                        <div class="set_participant_transfer_content">
                          <el-transfer
                            v-model="formData.setParticipant.participantList"
                            filterable
                            target-order="push"
                            :filter-placeholder="$t('text.placeholder')"
                            :titles="[
                              $t('activity.title_allparticipant'),
                              $t('activity.title_selectedparticipant'),
                            ]"
                            :data="pageData.participantList"
                            :props="{
                              key: 'username',
                              label: 'englishname',
                            }"
                          >
                            <template #default="{ option }">
                              <div v-if="pageData.lang == 'ch'">
                                {{ option.fullname }}
                              </div>
                              <div v-else-if="pageData.lang == 'en'">
                                {{ option.englishname }}
                              </div>
                              <div v-else>
                                {{ option.englishname }}
                              </div>
                            </template>
                          </el-transfer>
                        </div>
                      </el-form-item>
                    </el-form>
                    <template #footer>
                      <span>
                        <el-button
                          class="editActivityBtn"
                          type="success"
                          size="medium"
                          :disabled="
                            formData.setParticipant.participantList.length <=
                              0 || pageData.setParticipationDisabled
                          "
                          @click="
                            doSetParticipantForAct(formData.setParticipant)
                          "
                          :loading="pageData.doSetParticipantForActLoading"
                          >{{ $t("target.btn_save") }}</el-button
                        >
                      </span>
                    </template>
                  </el-dialog>

                  <div class="setrelations_content">
                    <el-dialog
                      width="100%"
                      :title="$t('activity.title_set_relations')"
                      v-model="pageData.setRelationsEditerVisible"
                      ref="setRelationsEditer"
                      :modal="true"
                      :close-on-click-modal="false"
                      :close-on-press-escape="false"
                      :center="true"
                    >
                      <el-backtop
                        target=".el-dialog__body"
                        :right="10"
                      ></el-backtop>
                      <el-button
                        style="float: right; margin-bottom: 10px"
                        type="primary"
                        size="small"
                        @click="exportTemplate"
                        :disabled="true"
                        >{{ $t("target.btn_exporttp") }}</el-button
                      >
                      <el-table
                        stripe
                        border
                        ref="relationsTable"
                        :header-cell-style="{
                          textAlign: 'center',
                          backgroundColor: '#f2f6fc',
                        }"
                        :cell-style="{ textAlign: 'center' }"
                        :data="pageData.participantListForRelations"
                      >
                        <el-table-column type="index" width="50">
                        </el-table-column>
                        <el-table-column
                          :label="$t('activity.label_participant')"
                        >
                          <template #default="scope">
                            <span v-if="pageData.lang == 'ch'">{{
                              scope.row.fullname
                            }}</span>

                            <span v-else-if="pageData.lang == 'en'">
                              {{ scope.row.englishname }}</span
                            >
                            <span v-else> {{ scope.row.englishname }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="$t('activity.label_questionnaire_relations')"
                        >
                          <template #default="scope">
                            <div v-if="scope.row.questionnaire_name != null">
                              {{ scope.row.questionnaire_name }}
                            </div>
                            <div v-else style="color: #f56c6c">
                              {{ $t("text.text_notset") }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column :label="$t('activity.label_superior')">
                          <template #default="scope">
                            <div v-if="scope.row.superior.length > 0">
                              <span
                                v-for="(superior, index) in scope.row.superior"
                                :key="index"
                                :index="index"
                                >[{{ superior.label }}]</span
                              >
                            </div>
                            <div v-else style="color: #f56c6c">
                              {{ $t("text.text_notset") }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column :label="$t('activity.label_equal')">
                          <template #default="scope">
                            <div v-if="scope.row.equal.length > 0">
                              <span
                                v-for="(equal, index) in scope.row.equal"
                                :key="index"
                                :index="index"
                                >[{{ equal.label }}]</span
                              >
                            </div>
                            <div v-else style="color: #f56c6c">
                              {{ $t("text.text_notset") }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="$t('activity.label_subordinate')"
                        >
                          <template #default="scope">
                            <div v-if="scope.row.subordinate.length > 0">
                              <span
                                v-for="(subordinate, index) in scope.row
                                  .subordinate"
                                :key="index"
                                :index="index"
                                >[{{ subordinate.label }}]</span
                              >
                            </div>
                            <div v-else style="color: #f56c6c">
                              {{ $t("text.text_notset") }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column :label="$t('activity.label_self')">
                          <template #default="scope">
                            <span v-if="scope.row.self">{{
                              $t("text.text_yes")
                            }}</span>
                            <span v-else>{{ $t("text.text_no") }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="$t('activity.label_result')"
                          v-if="
                            pageData.participantListForRelations.status !== 0
                          "
                        >
                          <template #default="scope">
                            <el-button
                              @click="showResult(scope.row)"
                              type="success"
                              circle
                              size="small"
                              class="el-icon-s-data"
                              :disabled="
                                pageData.participantListForRelations.process < 1
                              "
                            ></el-button>
                            <el-button
                              @click="toPrint(scope.row)"
                              type="success"
                              circle
                              size="small"
                              class="el-icon-printer"
                              :disabled="
                                pageData.participantListForRelations.process < 1
                              "
                            ></el-button>
                          </template>
                        </el-table-column>
                        <el-table-column :label="$t('activity.label_options')">
                          <template #default="scope">
                            <el-button
                              circle
                              class="el-icon-edit"
                              type="primary"
                              size="small"
                              @click="openRelationsReviser(scope.row)"
                              :disabled="
                                pageData.participantListForRelations.status !==
                                0
                              "
                            ></el-button>
                          </template>
                          <!-- TODO:Reviser -->
                        </el-table-column>
                      </el-table>
                    </el-dialog>
                  </div>

                  <!-- TODO:HTML -->
                  <el-drawer
                    :title="pageData.reviseParticipationDrawer.title"
                    v-model="pageData.reviseParticipationDrawer.visible"
                    :before-close="reviseParticipationDrawerClose"
                    custom-class="reviseParticipation_Drawer"
                    ref="reviseParticipation_Drawer"
                    @opened="focusOpened"
                    :close-on-press-escape="false"
                  >
                    <div class="reviseParticipationForm_content">
                      <el-form
                        :model="formData.reviseParticipation"
                        ref="form_reviseParticipation"
                        class="form_reviseParticipation"
                        size="mini"
                        :label-position="pageData.reviseFormlabelPosition"
                        label-width="100px"
                      >
                        <el-form-item
                          :label="$t('activity.label_questionnaire_relations')"
                        >
                          <el-select
                            v-model="
                              formData.reviseParticipation.questionnaire_id
                            "
                            filterable
                            size="small"
                            style="width: 40%"
                            :placeholder="$t('text.placeholder_select')"
                          >
                            <el-option
                              v-for="(
                                item, index
                              ) in pageData.activityQuestionnaireList"
                              :key="index"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item :label="$t('activity.label_superior')">
                          <el-row>
                            <el-button
                              circle
                              class="el-icon-plus"
                              type="success"
                              style="margin-bottom: 15px"
                              @click="addSuperior"
                            ></el-button
                          ></el-row>

                          <el-col
                            v-for="(superior, index) in formData
                              .reviseParticipation.superior"
                            :key="index"
                            :index="index"
                          >
                            <div
                              style="margin-right: 10px; margin-bottom: 10px"
                            >
                              <el-select
                                v-model="superior.evaluator"
                                filterable
                                size="small"
                                style="width: 80%"
                                :placeholder="$t('text.placeholder_select')"
                              >
                                <el-col>
                                  <el-option
                                    v-for="(item, index) in removeCurrentUser(
                                      formData.reviseParticipation.username,
                                      pageData.participantListI18n
                                    )"
                                    :key="index"
                                    :label="item.labelname"
                                    :value="item.evaluator"
                                  ></el-option>
                                </el-col>
                              </el-select>
                              <el-button
                                circle
                                class="el-icon-minus"
                                type="danger"
                                @click="deleteSuperior(index)"
                              ></el-button>
                            </div>
                          </el-col>
                        </el-form-item>

                        <el-form-item :label="$t('activity.label_equal')">
                          <el-row>
                            <el-button
                              circle
                              class="el-icon-plus"
                              type="success"
                              style="margin-bottom: 15px"
                              @click="addEqual"
                            ></el-button
                          ></el-row>
                          <el-col
                            v-for="(equal, index) in formData
                              .reviseParticipation.equal"
                            :key="index"
                            :index="index"
                          >
                            <div
                              style="margin-right: 10px; margin-bottom: 10px"
                            >
                              <el-select
                                v-model="equal.evaluator"
                                filterable
                                size="small"
                                style="width: 80%"
                                :placeholder="$t('text.placeholder_select')"
                              >
                                <el-col>
                                  <el-option
                                    v-for="(item, index) in removeCurrentUser(
                                      formData.reviseParticipation.username,
                                      pageData.participantListI18n
                                    )"
                                    :key="index"
                                    :label="item.labelname"
                                    :value="item.evaluator"
                                  ></el-option>
                                </el-col>
                              </el-select>
                              <el-button
                                circle
                                class="el-icon-minus"
                                type="danger"
                                @click="deleteEqual(index)"
                              ></el-button>
                            </div>
                          </el-col>
                        </el-form-item>

                        <el-form-item
                          :label="$t('activity.label_subordinate')"
                          v-if="pageData.currentEvaUserInfo.is_manager === 1"
                        >
                          <el-row>
                            <el-button
                              circle
                              class="el-icon-plus"
                              type="success"
                              style="margin-bottom: 15px"
                              @click="addSubordinate"
                            ></el-button
                          ></el-row>
                          <el-col
                            v-for="(subordinate, index) in formData
                              .reviseParticipation.subordinate"
                            :key="index"
                            :index="index"
                          >
                            <div
                              style="margin-right: 10px; margin-bottom: 10px"
                            >
                              <el-select
                                v-model="subordinate.evaluator"
                                filterable
                                size="small"
                                style="width: 80%"
                                :placeholder="$t('text.placeholder_select')"
                              >
                                <el-col>
                                  <el-option
                                    v-for="(item, index) in removeCurrentUser(
                                      formData.reviseParticipation.username,
                                      pageData.participantListI18n
                                    )"
                                    :key="index"
                                    :label="item.labelname"
                                    :value="item.evaluator"
                                  ></el-option>
                                </el-col>
                              </el-select>
                              <el-button
                                circle
                                class="el-icon-minus"
                                type="danger"
                                @click="deleteSubordinate(index)"
                              ></el-button>
                            </div>
                          </el-col>
                        </el-form-item>

                        <el-form-item :label="$t('activity.label_self')">
                          <el-switch
                            v-model="formData.reviseParticipation.self"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                          >
                          </el-switch>
                        </el-form-item>

                        <el-form-item>
                          <el-button
                            type="primary"
                            size="medium"
                            style="margin-top: 50px; float: right"
                            @click="saveRelation(formData.reviseParticipation)"
                            :loading="pageData.setActRelationLoading"
                            >{{ $t("common.btn_save") }}</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-drawer>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  //TODO:DATA
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        activityList: [],
        participantList: [],
        participantListI18n: [],
        enableQuestionnaireList: [],
        participantListForRelations: [],
        questionnaireListInActivity: [],
        activityQuestionnaireList: [],
        activityAdderVisible: false,
        doAddActivityLoading: false,
        activityReviserVisible: false,
        doEditActivityLoading: false,
        setQuestionnaireEditerVisible: false,
        setParticipantEditerVisible: false,
        doEditQuestionnaireForActLoading: false,
        doEditQuestionnaireForActBtnDisabled: false,
        setActQuestionnaireLoading: false,
        setActPacrticipantLoading: false,
        setActRelationsLoading: false,
        setActRelationLoading: false,
        doSetParticipantForActLoading: false,
        setRelationsEditerVisible: false,
        setParticipationDisabled: false,
        currentActivity: {},
        reviseFormlabelPosition: 'top',
        reviseParticipationDrawer: {
          title: '',
          visible: false
        },
        currentEvaUserInfo: {},
        activityStart: {
          id: '',
          relationsFinished: false
        },
        autoColors: [
          { color: '#409eff', percentage: 99.99 },
          { color: '#67c23a', percentage: 100 },
        ],
      },
      formData: {
        newActivity: {
          name: '',
          name_en: ''
        },
        editActivity: {
          name: '',
          name_en: ''
        },
        setQuestionnaire: {
          questionnaireList: []
        },
        setParticipant: {
          participantList: []
        },
        reviseParticipation: {
          id: '',
          username: '',
          activity_id: '',
          questionnaire_id: '',
          superior: [],
          equal: [],
          subordinate: [],
          self: true
        }
      }
    };
  },
  mounted() {
    // document.title = this.$t('title.');
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    if (mountederjs.evaluateM(this)) {
      this.getActivityList();
      this.getParticipants();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    getActivityList() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/getActivities',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.activityList = response.data.data;

            // for (let act of this.pageData.activityList) {
            //   // console.log(act.process.toFixed(2))
            //   act.process = Number(act.process.toFixed(2));
            //   console.log(act.process)
            //   // act.process = Math.round(scope.row.process * 100) / 100;
            // }

            this.getAllQuestionnaireList();

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('activity.notify_actnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    actFormatter(percentage) {
      return percentage.toFixed(0) + "%";
    },
    getParticipants() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryEvaluateUsers',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.participantList = response.data.data;

            this.pageData.participantListI18n = response.data.data;
            for (let item of this.pageData.participantListI18n) {
              if (this.pageData.lang == 'ch') {
                item.evaluator = item.username;
                item.labelname = item.fullname + ' - ' + item.dept_name;
              } else {
                item.evaluator = item.username;
                item.labelname = item.englishname + ' - ' + item.dept_name_en;
              }
            }

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('staff.notify_staffnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getAllQuestionnaireList() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestionnaireListForAct',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.enableQuestionnaireList = response.data.data;

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('activity.notify_questionnairenull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    openActivityAdder() {
      this.pageData.activityAdderVisible = true;
    },
    doAddActivity(activity) {
      this.pageData.doAddActivityLoading = true;

      for (let index in activity) {
        if (utilsjs.strIsEmpty(activity[index])) {
          this.pageData.doAddActivityLoading = false;
          this.$notify.error({
            title: this.$t("common.notity_title_message"),
            message: this.$t('activity.notify_incompletely')
          });
          return;
        } else {
          continue;
        }
      }

      //set values
      activity.creator = this.pageData.username;
      activity.reviser = this.pageData.username;

      //post
      this.postToAddActivity(activity);
    },
    postToAddActivity(activity) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/createActivity',
        data: activity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doAddActivityLoading = false;
            this.pageData.activityAdderVisible = false;

            setTimeout(() => {
              location.reload();
            }, 800)
            return;
          } else {
            //code = 700 and more
            this.pageData.doAddActivityLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doAddActivityLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doAddActivityLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    reviseActPrimary(activity) {
      this.pageData.oldActivity = '';
      this.pageData.oldActivity = JSON.parse(JSON.stringify(activity));
      //set values
      this.formData.editActivity.id = activity.id;
      this.formData.editActivity.name = activity.name;
      this.formData.editActivity.name_en = activity.name_en;
      this.formData.editActivity.reviser = this.pageData.username;

      this.pageData.activityReviserVisible = true;
    },
    doEditActPrimary(activity) {
      this.pageData.doEditActivityLoading = true;

      if (activity.name !== this.pageData.oldActivity.name || activity.name_en !== this.pageData.oldActivity.name_en) {
        for (let index in activity) {
          if (utilsjs.strIsEmpty(activity[index])) {
            this.pageData.doEditActivityLoading = false;
            this.$notify.error({
              title: this.$t("common.notity_title_message"),
              message: this.$t('activity.notify_incompletely')
            });
            return;
          } else {
            continue;
          }
        }
      } else {
        // no change
        this.pageData.doEditActivityLoading = false;
        this.pageData.activityReviserVisible = false;

        this.$ElMessage.warning({
          message: this.$t("text.message_nochange"),
          type: 'success'
        });
        return;
      }

      //pass
      this.postToReviseActPrimary(activity, 'doEditActivityLoading');
    },
    postToReviseActPrimary(activity, loadingName) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/reviseActivity',
        data: activity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData[loadingName] = false;

            setTimeout(() => {
              location.reload();
            }, 800)
            return;
          } else {
            //code = 700 and more
            this.pageData[loadingName] = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData[loadingName] = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData[loadingName] = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    setActQuestionnaire(activity) {
      this.pageData.setActQuestionnaireLoading = true;
      this.pageData.currentActivity = {};
      this.formData.setQuestionnaire.questionnaireList = [];

      for (let questionnaire of this.pageData.enableQuestionnaireList) {
        if (activity.qstatus !== 0) {
          questionnaire.disabled = true;
          this.pageData.doEditQuestionnaireForActBtnDisabled = true;
        } else {
          questionnaire.disabled = false;
          this.pageData.doEditQuestionnaireForActBtnDisabled = false;
        }
      }

      this.getQuestionnaireByActivity(activity);
    },
    doEditQuestionnaireForAct(questionnaires) {
      this.pageData.doEditQuestionnaireForActLoading = true;
      let questionnaireListPost = [];

      if (questionnaires.questionnaireList.length <= 0) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('activity.notify_questionnaireinactnull')
        });

        this.pageData.doEditQuestionnaireForActLoading = false;
        return;
      }

      for (let questionnaire of questionnaires.questionnaireList) {
        let questionnaireConnection = {};
        questionnaireConnection.questionnaire_id = questionnaire;
        questionnaireConnection.activity_id = this.pageData.currentActivity.id;
        questionnaireConnection.creator = this.pageData.username;
        questionnaireListPost.push(questionnaireConnection);
      }
      this.pageData.currentActivity.questionnaireConnectionList = JSON.parse(JSON.stringify(questionnaireListPost));

      //post
      this.$confirm(this.$t("activity.text_delete_message_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        iconClass: 'el-icon-question'
      }).then(() => {
        this.postToSetQuestionnaire(this.pageData.currentActivity);
      }).catch(() => {
        this.pageData.doEditQuestionnaireForActLoading = false;
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    postToSetQuestionnaire(currentActivity) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/insertQuestionnaireToActivity',
        data: currentActivity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doEditQuestionnaireForActLoading = false;
            this.pageData.setQuestionnaireEditerVisible = false;

            setTimeout(() => {
              location.reload();
            }, 1000);

            return;
          } else if (response.data.code == '290') {
            this.pageData.doEditQuestionnaireForActLoading = false;
            this.$notify.warning({
              title: this.$t("notify.title_err_type1"),
              message: this.$t('activity.notify_questionnaire_err_apart')
            });

            setTimeout(() => {
              location.reload();
            }, 1000);

            return;
          } else if (response.data.code == '701') {
            this.pageData.doEditQuestionnaireForActLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_err_type_ill"),
              message: this.$t('notify.content_ill')
            });

            return;
          } else {
            //code = 700 and more
            this.pageData.doEditQuestionnaireForActLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doEditQuestionnaireForActLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doEditQuestionnaireForActLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    setActParticipant(activity) {
      this.pageData.setActPacrticipantLoading = true;
      this.pageData.currentActivity = {};
      this.formData.setParticipant.participantList = [];

      for (let participant of this.pageData.participantList) {
        if (activity.rstatus !== 0) {
          participant.disabled = true;
          this.pageData.setParticipationDisabled = true;
        } else {
          participant.disabled = false;
          this.pageData.setParticipationDisabled = false;
        }
      }

      this.getParticipationByActivity(activity);
    },
    getParticipationByActivity(activity) {
      this.pageData.currentActivity = JSON.parse(JSON.stringify(activity));

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/selectPacrticipation',
        data: activity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            for (let index in response.data.data) {
              this.formData.setParticipant.participantList.push(response.data.data[index].username);
            }

            this.pageData.setActPacrticipantLoading = false;
            this.pageData.setParticipantEditerVisible = true;

            return;
          } else if (response.data.code == '210') {
            this.pageData.setActPacrticipantLoading = false;
            this.pageData.setParticipantEditerVisible = true;

            return;
          } else {
            //code = 700 and more
            this.pageData.setActPacrticipantLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.setActPacrticipantLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.setActPacrticipantLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    doSetParticipantForAct(participants) {
      this.pageData.doSetParticipantForActLoading = true;
      let createParticipationList = [];
      for (let participant of participants.participantList) {
        let participantObj = {};
        participantObj.username = participant;
        participantObj.activity_id = this.pageData.currentActivity.id;
        participantObj.questionnaire_id = "";
        participantObj.creator = this.pageData.username;
        createParticipationList.push(participantObj);
      }

      //post
      this.$confirm(this.$t("activity.text_participant_message_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        iconClass: 'el-icon-question'
      }).then(() => {
        this.postToSetParticipation(createParticipationList);
      }).catch(() => {
        this.pageData.doSetParticipantForActLoading = false;
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    //TODO:create
    postToSetParticipation(createParticipationList) {
      this.pageData.currentActivity.participationList = JSON.parse(JSON.stringify(createParticipationList));

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/createParticipation',
        data: this.pageData.currentActivity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doSetParticipantForActLoading = false;
            this.pageData.setParticipantEditerVisible = false;

            setTimeout(() => {
              location.reload();
            }, 1000);

            return;
          } else if (response.data.code == '290') {
            this.pageData.doSetParticipantForActLoading = false;
            this.$notify.warning({
              title: this.$t("notify.title_err_type1"),
              message: this.$t('activity.notify_questionnaire_err_apart')
            });

            setTimeout(() => {
              location.reload();
            }, 1000);

            return;
          } else {
            //code = 700 and more
            this.pageData.doSetParticipantForActLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doSetParticipantForActLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doSetParticipantForActLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    setActRelations(activity) {
      this.pageData.setActRelationsLoading = true;
      this.pageData.currentActivity = {};
      this.pageData.participantListForRelations = [];
      this.pageData.questionnaireListInActivity = [];
      this.pageData.activityQuestionnaireList = [];

      this.getQuestionnaireOfActivity(activity);
    },
    getQuestionnaireOfActivity(activity) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestionnaireByActivityId',
        data: activity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.questionnaireListInActivity = response.data.data;
            //set activityQuestionnaireList

            for (let q1 of this.pageData.questionnaireListInActivity) {
              for (let q2 of this.pageData.enableQuestionnaireList) {
                if (q1.questionnaire_id == q2.id) {
                  let obj = {};
                  obj.value = q2.id;
                  if (this.pageData.lang == 'ch') {
                    obj.label = q2.title;
                  } else {
                    obj.label = q2.title_en;
                  }
                  this.pageData.activityQuestionnaireList.push(obj);
                }
              }
            }

            this.getRelationsByActivity(activity);
            return;
          } else if (response.data.code == '210') {
            this.pageData.setActRelationsLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('activity.notify_questionnaire_null_relations')
            });

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getRelationsByActivity(activity) {
      this.pageData.activityStart.id = '';
      this.pageData.activityStart.relationsFinished = false;
      this.pageData.currentActivity = JSON.parse(JSON.stringify(activity));

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryRelations',
        data: activity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.participantListForRelations = response.data.data;

            let count = 0;
            for (let participant of this.pageData.participantListForRelations) {
              participant.superior = [];
              participant.equal = [];
              participant.subordinate = [];
              participant.self = true;
              if (participant.relationsList.length > 0 && participant.relationsList != null && participant.relationsList != undefined) {
                count++;
                for (let evaluator of participant.relationsList) {
                  let obj = {};
                  obj.evaluator = evaluator.evaluator;
                  if (evaluator.level == 0) {
                    if (this.pageData.lang == 'ch') {
                      obj.label = evaluator.fullname;
                      participant.subordinate.push(obj);
                    } else {
                      obj.label = evaluator.englishname;
                      participant.subordinate.push(obj);
                    }
                  } else if (evaluator.level == 1) {
                    if (this.pageData.lang == 'ch') {
                      obj.label = evaluator.fullname;
                      participant.equal.push(obj);
                    } else {
                      obj.label = evaluator.englishname;
                      participant.equal.push(obj);
                    }
                  } else if (evaluator.level == 2) {
                    if (this.pageData.lang == 'ch') {
                      obj.label = evaluator.fullname;
                      participant.superior.push(obj);
                    } else {
                      obj.label = evaluator.englishname;
                      participant.superior.push(obj);
                    }
                  } else if (evaluator.level == 3) {
                    participant.self = true;
                  }
                }
              } else {
                continue;
              }
            }
            if (count === this.pageData.participantListForRelations.length) {
              this.pageData.activityStart.id = activity.id;
              this.pageData.activityStart.relationsFinished = true;
            }

            //set questionnaire name
            for (let part of this.pageData.participantListForRelations) {
              for (let q1 of this.pageData.enableQuestionnaireList) {
                if (part.questionnaire_id == q1.id) {
                  if (this.pageData.lang == 'ch') {
                    part.questionnaire_name = q1.title;
                  } else {
                    part.questionnaire_name = q1.title_en;
                  }
                }
              }
            }

            this.pageData.participantListForRelations.status = activity.status;
            this.pageData.participantListForRelations.process = activity.process;

            this.pageData.setActRelationsLoading = false;
            this.pageData.setRelationsEditerVisible = true;

            return;
          } else if (response.data.code == '210') {
            this.pageData.setActRelationsLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('activity.notify_participant_null_relations')
            });

            return;
          } else {
            //code = 700 and more
            this.pageData.setActRelationsLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.setActRelationsLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.setActRelationsLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    exportTemplate() {
      // console.log(this.pageData.participantListForRelations)
    },
    showResult(participation) {
      let targetAskForm = this.$router.resolve({
        name: 'Result', query: {
          activity_id: participation.activity_id,
          participant: participation.username,
          questionnaire_id: participation.questionnaire_id
        }
      });
      window.open(targetAskForm.href, '_blank');
    },
    toPrint(participation) {
      let routername = null;
      if (this.pageData.lang == 'ch') {
        routername = '_ResultPt';
      } else {
        routername = '_ResultPt';
      }
      let targetAskForm = this.$router.resolve({
        name: routername, query: {
          activity_id: participation.activity_id,
          participant: participation.username,
          questionnaire_id: participation.questionnaire_id
        }
      });
      window.open(targetAskForm.href, '_blank');
    },
    // TODO:REVISER
    openRelationsReviser(participation) {
      this.getEvaUserInfo(participation);
    },
    reviseParticipationDrawerClose(done) {
      done();
    },
    getEvaUserInfo(participation) {
      let params = {};
      params.username = participation.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/user/queryEvaluateUserInfoByUsername',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.currentEvaUserInfo = {};
            this.pageData.currentEvaUserInfo = response.data.data;

            this.formData.reviseParticipation.superior = [];
            this.formData.reviseParticipation.equal = [];
            this.formData.reviseParticipation.subordinate = [];
            //set values
            this.pageData.reviseParticipationDrawer.title = this.$t("activity.title_revise_relation");

            this.formData.reviseParticipation.id = participation.id;
            this.formData.reviseParticipation.username = participation.username;
            this.formData.reviseParticipation.activity_id = participation.activity_id;
            this.formData.reviseParticipation.questionnaire_id = participation.questionnaire_id;
            this.formData.reviseParticipation.superior = JSON.parse(JSON.stringify(participation.superior));
            this.formData.reviseParticipation.equal = JSON.parse(JSON.stringify(participation.equal));
            this.formData.reviseParticipation.subordinate = JSON.parse(JSON.stringify(participation.subordinate));
            this.formData.reviseParticipation.self = participation.self;

            this.pageData.reviseParticipationDrawer.visible = true;
            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('staff.notify_staffnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    focusOpened() {

    },
    getQuestionnaireByActivity(activity) {
      this.pageData.currentActivity = JSON.parse(JSON.stringify(activity));

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/selectQuestionnaireByActivityIdWithoutIsdel',
        data: activity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            for (let index in response.data.data) {
              this.formData.setQuestionnaire.questionnaireList.push(response.data.data[index].id);
            }

            this.pageData.setActQuestionnaireLoading = false;
            this.pageData.setQuestionnaireEditerVisible = true;

            return;
          } else if (response.data.code == '210') {
            this.pageData.setActQuestionnaireLoading = false;
            this.pageData.setQuestionnaireEditerVisible = true;

            return;
          } else {
            //code = 700 and more
            this.pageData.setActQuestionnaireLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.setActQuestionnaireLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.setActQuestionnaireLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    addSuperior() {
      let newSuperior = {};
      newSuperior.evaluator = '';
      this.formData.reviseParticipation.superior.push(newSuperior)
    },
    deleteSuperior(index) {
      this.formData.reviseParticipation.superior.splice(index, 1);
    },
    addEqual() {
      let newEqual = {};
      newEqual.evaluator = '';
      this.formData.reviseParticipation.equal.push(newEqual)
    },
    deleteEqual(index) {
      this.formData.reviseParticipation.equal.splice(index, 1);
    },
    addSubordinate() {
      let newSubordinate = {};
      newSubordinate.evaluator = '';
      this.formData.reviseParticipation.subordinate.push(newSubordinate)
    },
    deleteSubordinate(index) {
      this.formData.reviseParticipation.subordinate.splice(index, 1);
    },
    //TODO:SAVE RELATION
    saveRelation(participation) {
      this.pageData.setActRelationLoading = true;
      if (utilsjs.strIsEmpty(participation.questionnaire_id) || utilsjs.strIsEmpty(participation.self)) {
        this.$notify.error({
          title: this.$t("notify.title_err_type_ill"),
          message: this.$t('activity.notify_questionnaire_self_null')
        });
        this.pageData.setActRelationLoading = false;
        return;
      }
      if (participation.equal.length <= 0 || participation.superior.length <= 0) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('activity.notify_relations_least')
        });
        this.pageData.setActRelationLoading = false;
        return;
      }
      //is manager?
      if (this.pageData.currentEvaUserInfo.is_manager === 1 && participation.subordinate.length <= 0) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('activity.notify_relations_least')
        });
        this.pageData.setActRelationLoading = false;
        return;
      }

      //distinct
      let j1 = {};
      let j2 = {};
      let j3 = {};
      participation.superior = participation.superior.reduce(function (item, next) {
        j1[next.evaluator] ? '' : j1[next.evaluator] = true && item.push(next);
        return item;
      }, []);
      participation.equal = participation.equal.reduce(function (item, next) {
        j2[next.evaluator] ? '' : j2[next.evaluator] = true && item.push(next);
        return item;
      }, []);
      if (participation.subordinate.length > 0) {
        participation.subordinate = participation.subordinate.reduce(function (item, next) {
          j3[next.evaluator] ? '' : j3[next.evaluator] = true && item.push(next);
          return item;
        }, []);
      }

      for (let item of participation.superior) {
        if (utilsjs.strIsEmpty(item)) {
          this.$notify.error({
            title: this.$t("notify.title_err_type1"),
            message: this.$t('activity.notify_super_null_err')
          });
          this.pageData.setActRelationLoading = false;
          return;
        }
      }
      for (let item of participation.equal) {
        if (utilsjs.strIsEmpty(item)) {
          this.$notify.error({
            title: this.$t("notify.title_err_type1"),
            message: this.$t('activity.notify_equal_null_err')
          });
          this.pageData.setActRelationLoading = false;
          return;
        }
      }
      if (participation.subordinate.length > 0) {
        for (let item of participation.subordinate) {
          if (utilsjs.strIsEmpty(item)) {
            this.$notify.error({
              title: this.$t("notify.title_err_type1"),
              message: this.$t('activity.notify_subordinate_null_err')
            });
            this.pageData.setActRelationLoading = false;
            return;
          }
        }
      }

      //pack data
      let relationsList = [];
      for (let item of participation.superior) {
        let relation = {};
        relation.participation_id = participation.id;
        relation.evaluator = item.evaluator;
        relation.level = 2;
        relation.creator = this.pageData.username;
        relationsList.push(relation);
      }
      for (let item of participation.equal) {
        let relation = {};
        relation.participation_id = participation.id;
        relation.evaluator = item.evaluator;
        relation.level = 1;
        relation.creator = this.pageData.username;
        relationsList.push(relation);
      }
      if (participation.subordinate.length > 0) {
        for (let item of participation.subordinate) {
          let relation = {};
          relation.participation_id = participation.id;
          relation.evaluator = item.evaluator;
          relation.level = 0;
          relation.creator = this.pageData.username;
          relationsList.push(relation);
        }
      }
      if (participation.self) {
        let relation = {};
        relation.participation_id = participation.id;
        relation.evaluator = participation.username;
        relation.level = 3;
        relation.creator = this.pageData.username;
        relationsList.push(relation);
      }
      participation.relationsList = JSON.parse(JSON.stringify(relationsList));
      participation.currentActivityStatus = this.pageData.currentActivity.status;

      //post
      this.postToSetRelation(participation);
    },
    postToSetRelation(participation) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/setParticipationRelations',
        data: participation
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.setActRelationLoading = false;

            this.getRelationsByActivity(this.pageData.currentActivity);

            this.pageData.reviseParticipationDrawer.visible = false;

            // setTimeout(() => {
            //   location.reload();
            // }, 1000);

            return;
          } else if (response.data.code == '290') {
            this.pageData.setActRelationLoading = false;

            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('activity.notify_relations_err_apart')
            });

            setTimeout(() => {
              location.reload();
            }, 1000);

            return;
          } else if (response.data.code == '701') {
            this.$notify.error({
              title: this.$t("notify.title_err_type_ill"),
              message: this.$t('notify.content_ill')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    removeCurrentUser(currentUser, arr) {
      for (let index in arr) {
        if (arr[index].username == currentUser) {
          arr.splice(index, 1);
        } else {
          continue;
        }
      }
      return arr;
    },
    startActivity(activity) {
      if (this.pageData.activityStart.relationsFinished && this.pageData.activityStart.id == activity.id) {
        this.$confirm(this.$t("activity.text_start_activity_body"), this.$t("text.text_confirm_title"), {
          confirmButtonText: this.$t("common.btn_message_confirm"),
          cancelButtonText: this.$t("common.btn_message_cancel"),
          type: 'success'
        }).then(() => {
          this.postToStartActivity(activity);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t("text.text_canceled")
          });
        });
      } else {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('activity.notify_relations_not_finish')
        });
      }
    },
    postToStartActivity(activity) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/startActivity',
        data: activity
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            setTimeout(() => {
              location.reload();
            }, 1000);

            return;
          } else if (response.data.code == '701') {
            this.$notify.error({
              title: this.$t("notify.title_err_type_ill"),
              message: this.$t('notify.content_ill')
            });

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.activity_content {
  margin-top: 10px;
  padding: 20px;
}
.activity_top_content .el-button {
  float: right;
  margin-bottom: 20px;
}
.countActivity {
  float: left;
  margin-left: 20px;
  font-size: 14px;
  margin-bottom: 15px;
  color: #f56c6c;
}
.addActivityBtn,
.editActivityBtn {
  margin-top: -30px;
  float: right;
}
.set_questionnaire_transfer_content,
.set_participant_transfer_content {
  margin-top: 20px;
}
.reviseParticipationForm_content {
  width: 500px;
  margin: 0 auto;
  padding: 10px;
}

.el-form-item {
  text-align: left;
}
</style>